import Typography from "typography"

const headerStackArray = [
  "Work Sans",
  "Helvetica Neue",
  "Segoe UI",
  "Helvetica",
  "Arial",
  "sans-serif",
]
const bodyStackArray = [
  // 'Lora',
  "Work Sans",
  // "Proza Libre",
  "Helvetica Neue",
  "Segoe UI",
  "Helvetica",
  "Arial",
  "sans-serif",
]

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.5,
  headerFontFamily: headerStackArray,
  bodyFontFamily: bodyStackArray,
  headerColor: `var(--headerText)`,
  bodyColor: `var(--text)`,
  googleFonts: [
    // {
    //   name: "Proza Libre",
    //   styles: ["400", "400i", "700", "700i"],
    // },
    // {
    //   name: "Lora",
    //   styles: ['400i'],
    // },
    {
      name: "Work Sans",
      styles: ["400", "400i", "600", "600i", "900", "900i"],
    },
  ],
  scaleRatio: 3,
  overrideStyles: () => ({
    h1: {
      fontWeight: 900,
    },
    "h2, h3, h4, h5, h6, b": {
      fontWeight: 600,
    },
  }),
})

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
export const headerStack = headerStackArray.join(",")
export const bodyStack = bodyStackArray.join(",")
