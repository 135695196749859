import React from "react"
import { css } from "@emotion/core"

const Dot = () => (
  <span
    css={css`
      color: var(--primary);
    `}
  >
    .
  </span>
)

export default Dot
