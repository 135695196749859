import React, { useContext } from "react"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons"
import { sun, moon } from "../styles/colours"
import { ThemeContext } from "../context/ThemeContext"

const DarkToggle = () => {
  const { colorMode, setColorMode } = useContext(ThemeContext)

  if (!colorMode) {
    return null
  }

  return (
    <Toggle>
      <Track>
        <Moon>
          <FontAwesomeIcon icon={faMoon} />
        </Moon>
        <Sun>
          <FontAwesomeIcon icon={faSun} />
        </Sun>
        <Thumb dark={colorMode === "dark"}>
          <ThumbLine />
          <ThumbLine />
          <ThumbLine />
          <ThumbLine />
          <Input
            aria-label="Turn on dark mode"
            type="checkbox"
            checked={colorMode === "dark"}
            onChange={ev => {
              setColorMode(ev.target.checked ? "dark" : "light")
            }}
          />
        </Thumb>
      </Track>
    </Toggle>
  )
}

const Moon = styled.div`
  display: flex;
  color: ${moon.color};
  border-radius: 8px 0 0 8px;
  border-top: 2px solid ${moon.border};
  border-bottom: 2px solid ${moon.border};
  border-left: 2px solid ${moon.border};
  padding: 0.5rem;
  padding-right: 1rem;
  background-color: ${moon.background};
  vertical-align: middle;
  line-height: 1rem;
`

const Sun = styled.span`
  display: flex;
  color: ${sun.color};
  border-radius: 0 8px 8px 0;
  border-top: 2px solid ${sun.border};
  border-bottom: 2px solid ${sun.border};
  border-right: 2px solid ${sun.border};
  padding: 0.5rem;
  padding-left: 1rem;
  background-color: ${sun.background};
  vertical-align: middle;
  line-height: 1rem;
`

const Toggle = styled.label`
  cursor: pointer;
`

const Track = styled.div`
  display: flex;
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
`

const Thumb = styled.div`
  position: absolute;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary);
  border-radius: 4px;
  height: 100%;
  width: calc(3rem + 2px);
  top: 0;
  transform: ${({ dark }) => (dark ? `translateX(calc(2rem + 2px))` : "")};
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &:focus-within {
    box-shadow: 0px 0px 4px 4px var(--primaryDarker);
  }
`

const ThumbLine = styled.div`
  background-color: var(--primaryDarker);
  width: 2px;
  height: 100%;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
`

export default DarkToggle
