import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { rhythm } from "../utils/typography"
import Dot from "../components/Dot"
import Nav from "../components/Nav"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import DarkToggle from "./DarkToggle"

const Left = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
`

const Wrapper = styled("header")`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rhythm(1)};
`

const HomeLink = styled(Link)`
  box-shadow: none;
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`

const headerTitle = css`
  margin-bottom: 0;
  margin: 0 ${rhythm(1)} 0 0;
`

const Header = () => {
  const { title } = useSiteMetadata()
  const header = (
    <h3 css={headerTitle}>
      <HomeLink to={`/`}>
        {title}
        <Dot />
      </HomeLink>
    </h3>
  )

  return (
    <Wrapper>
      <Left>
        {header}
        <Nav />
      </Left>
      <DarkToggle />
    </Wrapper>
  )
}

export default Header
