import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { rhythm, scale, headerStack } from "../utils/typography"

const NavList = styled("ul")`
  display: flex;
  list-style-type: none;
  margin: 0;
`

const NavItem = styled.li({
  ...scale(1/4),
  fontFamily: headerStack,
  margin: `0 ${rhythm(1)} 0 0`,
})

const activeLink = {
  textDecoration: `underline var(--primary)`,
}

const NavLink = styled(Link)`
  text-decoration: none;
  &:hover {
    ${activeLink};
  }
`

const Nav = () => {
  return (
    <nav>
      <NavList>
        <NavItem>
          <NavLink to="/blog/" activeStyle={activeLink} partiallyActive>Blog</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/contact/" activeStyle={activeLink}>Contact</NavLink>
        </NavItem>
      </NavList>
    </nav>
  )
}

export default Nav
